.sidebar-app {
   min-height: 100vh;
   overflow-y: auto;

}
.sidebar-nav {
   background-color: #151529;
   min-height: 100vh;
}
.accordion-body {
   background-color: #151529 !important;
   border: none !important;
   color: aliceblue;

}

.accordion-button::after  {
   color: white;
}
.accordion-item:first-of-type .accordion-button  {
   background-color: #151529 !important;
   border: none !important;
   color: aliceblue;
   
}
.accordion-item:last-of-type .accordion-button.collapsed{
   background-color: #151529 !important;
   border: none !important;
   color: aliceblue;

}