.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 150%;
    /* background-color: white; */
}
.bouton{
    display: flex;
    width: 150px;
    height: 40px;
    background-color: #850D0E;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 20px;
}
.bouton:hover{
    box-shadow: #850D0E;
}
#nav{
    text-decoration: none;
    color: white;
}
