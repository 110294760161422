.calcs{
    position: relative;
    
    
}
.calculatrice-btn {
    position: absolute;
    top: 0;
    right: 0;
}
.btn-calc {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 350px;
    height: 30;
    font-size: 0.2rem;
   
}
.css-hayew9 {
    font-size: 1.7rem !important;
}
.css-h2okw {
    height: 80vh !important;
}