.login-app {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.login-app-dragon {
    display: flex;
}


.login {
    padding: 2.8rem;
    border-radius: 0.2rem;
    box-shadow: 2px 2px 2px 2px #e9d1d1;
    background-color: #ffffff;
    width: 430px;
}

.title {
    display: flex;
   color: #F9BA0B;
   justify-content: center;
}
.btn-login-dragon {
    display: block;
    width: 100%;
    margin-top: 4rem;
}

#icon-eye{
   position: absolute;
   margin-left: 310px;
   margin-top: -26px;
}