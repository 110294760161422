@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #F9BA0B; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .others{
    min-height: 100vh;
  }